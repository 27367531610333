if (document.ni.__webpack_public_path__) {
  // eslint-disable-next-line camelcase
  __webpack_public_path__ = document.ni.__webpack_public_path__;
}

require([
  'TOP10/ninja/components/advertiser-disclosure/assets/js/index',
  'TOP10/ninja/components/bg-search-form/assets/js/index',
  'TOP10/ninja/components/breadcrumb/assets/js/index',
  'TOP10/ninja/components/categories-shortlist/assets/js/index',
  'TOP10/ninja/components/chart-dynamic-filters/assets/js/index',
  'TOP10/ninja/components/chart-filters/assets/js/index',
  'TOP10/ninja/components/chart-mini-reviews/assets/js/index',
  'TOP10/ninja/components/chart-sortby/assets/js/index',
  'TOP10/ninja/components/cookie-policy/assets/js/index',
  'TOP10/ninja/components/footer/assets/js/index',
  'TOP10/ninja/components/header/assets/js/index',
  'TOP10/ninja/components/image-carousel/assets/js/index',
  'TOP10/ninja/components/legal-declarations/assets/js/index',
  'TOP10/ninja/components/menu-categories/assets/js/index',
  'TOP10/ninja/components/promotion/assets/js/index',
  'TOP10/ninja/components/related-articles/assets/js/index',
  'TOP10/ninja/components/search-bar/assets/js/index',
  'TOP10/ninja/components/smart-quiz/assets/js/index',
  'TOP10/ninja/components/social-share-buttons/assets/js/index',
  'TOP10/ninja/components/stars-rating/assets/js/index',
  'TOP10/ninja/components/top-3-products/assets/js/index',
  'TOP10/ninja/components/chart-score-form/assets/js/index',
  'TOP10/ninja/components/chart-kdfs-filter/assets/js/index',
  'TOP10/ninja/components/chart-score-filter/assets/js/index',
  'TOP10/ninja/components/pedestal/assets/js/index',
  'TOP10/ninja/components/phone/assets/js/index',
  'TOP10/ninja/components/bottom-three/assets/js/index',
  'TOP10/ninja/components/services-not-available-disclaimer/assets/js/index',
  'TOP10/ninja/components/user-profile/assets/js/index',
  'TOP10/ninja/components/faq/assets/js/index',
  'TOP10/ninja/components/intent-quiz/assets/js/index',
  'TOP10/ninja/components/prequalification/assets/js/index',
  'TOP10/ninja/components/cookie-poptin/assets/js/index',
  'TOP10/ninja/components/related-partner-deals/assets/js/index',
], function clbck() {
  // Init Essentials
  require.ensure(['lazyload', 'OverlayScrollbars'], function onRequireEnsure() {
    var LazyLoad = require('lazyload');
    var OverlayScrollbars = require('OverlayScrollbars');
    OverlayScrollbars(document.querySelector('[data-role="dropdown-items"]'), {
      className: 'os-theme-thin-dark',
      resize: 'n',
    });

    document.ni.lazyLoadInstance = new LazyLoad({
      elements_selector: ['[data-src]', '.lazy', '[lazy]'],
    });
  });

  // Init Component Modules
  for (var i = 0; i < arguments.length; i++) {
    // eslint-disable-next-line prefer-rest-params
    var module = arguments[i];
    var moduleType = typeof module === 'function';
    try {
      if (moduleType) {
        module();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error', e);
    }
  }

  // Core Scripts
  var wysiwyg = require('coreScripts/wysiwyg/index');
  var showMore = require('coreScripts/show-more-toggle');
  var applyScrollbars = require('coreScripts/scrollbar');
  var filterByZipCode = require('coreScripts/chart-filter/filter-by-zip-code');
  var helpers = require('coreScripts/helpers');
  var chartUIEvents = require('coreScripts/chart/ui-events');
  var faqHelpers = require('coreScripts/faq-helpers');

  // Ninja UI Components
  var chartProductsListComponent = require('TOP10/ninja/components/chart-products-list/assets/js/index');
  var chartProductComponent = require('TOP10/ninja/components/chart-product/assets/js/index');
  var socialProofComponent = require('TOP10/ninja/components/social-proof/assets/js/index');
  var scoreComponent = require('TOP10/ninja/components/score/assets/js/index');
  var initMiniReviewsComponent = require('TOP10/ninja/components/chart-mini-reviews/assets/js/index');
  var chartFiltersComponent = require('TOP10/ninja/components/chart-score-filter/assets/js/index');
  var chartScoreFilterComponent = require('TOP10/ninja/components/chart-filters/assets/js/index');
  var starRatingComponent = require('TOP10/ninja/components/stars-rating/assets/js/index');
  var scoreForm = require('TOP10/ninja/components/chart-score-form/assets/js/index');
  var kdfsFilter = require('TOP10/ninja/components/chart-kdfs-filter/assets/js/index');
  var swiftype = require('TOP10/common/assets/js/search/swiftype.js');
  var visitorsReviews = require('TOP10/ninja/components/visitors-reviews/assets/js/index.js');

  function onSuccessfulFilter() {
    chartUIEvents.toggleUIEvents('off');
    chartProductsListComponent.onFilterReInit();
    chartProductComponent.init();
    showMore.initialize();
    socialProofComponent.init();
    initMiniReviewsComponent();
    scoreComponent.init(true);

    chartUIEvents.toggleUIEvents();
    if (document.ni && document.ni.lazyLoadInstance) {
      document.ni.lazyLoadInstance.update();
    }
  }

  function editorialReviewIcon() {
    helpers.imageUrlFallback(
      '[data-role="editorial-review-icon"]',
      '//umbrella.data.naturalint.com/production/products/uploads/photo/product-default-icon.svg'
    );
  }

  function accordionOpenFirstPartner() {
    // Toggle accordion for first partner only if feature toggle is on 'ni-accordion-open-ft'
    var $firstPartnerAccordionBtn = $('[data-accordion-open-first="true"] button');
    if ($firstPartnerAccordionBtn.length) {
      $(window).on('scroll', function shouldToggleAccordion() {
        if (window.scrollY >= 10) {
          $(window).off('scroll', shouldToggleAccordion);
          $firstPartnerAccordionBtn
            .closest('[data-role="chart-product-body"]')
            .find('[data-role="accordion"]')
            .data('open', true);
          var closingText = $firstPartnerAccordionBtn.data('text-close');
          $firstPartnerAccordionBtn.data('is-open-state', true);
          $firstPartnerAccordionBtn.find('[data-role="toggle-accordion-text"]').html(closingText);
          ni.uiEvents.trackEvent({
            category: 'Chart',
            action: 'Scroll',
            optLabel: 'Open accordion on scroll',
            optValue: 1,
            optNonInteraction: true,
          });
        }
      });
    }
  }

  $(document).ready(function onDocReady() {
    faqHelpers.onFaqScroll();
    chartProductsListComponent.init(onSuccessfulFilter);
    chartProductComponent.init();
    showMore.initialize();
    socialProofComponent.init();
    chartFiltersComponent.init();
    chartScoreFilterComponent.init();
    chartUIEvents.toggleUIEvents();
    editorialReviewIcon();
    chartUIEvents.isFirstCTAInViewPort();
    wysiwyg({
      enableTableCompactView: true,
      wysiwygContainer: '.chart-wysiwyg__html',
      zoomImage: true,
    });
    applyScrollbars();
    accordionOpenFirstPartner();
    filterByZipCode.initAPI(onSuccessfulFilter);
    starRatingComponent.init();
    scoreComponent.init();
    scoreForm.init();
    kdfsFilter.init(onSuccessfulFilter);
    swiftype();
    visitorsReviews.initFeedbackIcon('Chart Page');
  });
});
